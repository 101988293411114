<template>
  <div v-if="showPopup" class="popup-overlay">
    <div class="popup-container">
      <button class="close-button" @click="closePopup">×</button>
      <h2 class="padding-bottom-xsmall">Sign up for the monthly game release Newsletter!</h2>
      <form class="form" id="newsletter-form" @submit.prevent="submitNewsletter">
        <div class="display-horizontal-flex padding-bottom-xsmall">
          <input class="text-field" type="email" v-model="newsletterEmail" placeholder="Your email" required />
          <button class="submit-button" :class="{ disabled: !acceptTerms }" type="submit" :disabled="!acceptTerms">
            {{ buttonLabel }}
          </button>
        </div>
        <label class="checkbox-field">
          <input class="checkbox" type="checkbox" id="acceptTerms" v-model="acceptTerms" required />
          <span class="checkbox-label padding-left-xsmall" for="acceptTerms"
            >I accept the <a href="/terms-and-conditions" target="_blank" class="link">terms and conditions</a></span
          >
        </label>
      </form>
      <div class="popup-footer">
        <button class="dont-show-again" @click="dontShowAgain">Don't show again</button>
      </div>
      <transition name="fade">
        <div v-if="message" :class="messageType === 'error' ? 'error-box' : 'success-box'" class="message-box">
          {{ message }}
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "NewsletterPopup",
  data() {
    return {
      showPopup: false,
      newsletterEmail: "",
      acceptTerms: false,
      message: "",
      messageType: "",
      isLeavingPage: false,
      popupLastShown: null,
      popupShowDelay: 5 * 60 * 1000, // 5 minutes
      pageEngagementTime: 30 * 1000, // 30 seconds
      hasMetEngagementTime: false,
    };
  },
  mounted() {
    // Add mouseleave event to detect when cursor leaves the window
    document.addEventListener("mouseleave", this.handleMouseLeave);

    // Start engagement timer
    setTimeout(() => {
      this.hasMetEngagementTime = true;
    }, this.pageEngagementTime);
  },
  beforeUnmount() {
    // Clean up event listeners
    document.removeEventListener("mouseleave", this.handleMouseLeave);
  },
  methods: {
    handleMouseLeave(event) {
      if (localStorage.getItem("dontShowNewsletterPopup") === "true") {
        return;
      }

      // Only show popup if user has been on the page long enough
      if (!this.hasMetEngagementTime) return;

      // Check if we should show the popup based on time since last shown
      const now = Date.now();
      const canShowAgain = !this.popupLastShown || now - this.popupLastShown > this.popupShowDelay;

      // Only trigger if the mouse is leaving from the top of the page,
      // user hasn't subscribed yet, and enough time has passed
      if (
        event.clientY <= 0 &&
        localStorage.getItem("newsletterSubscribed") !== "true" &&
        !this.isLeavingPage &&
        canShowAgain
      ) {
        this.isLeavingPage = true;
        this.showPopup = true;
        this.popupLastShown = now;
      }
    },
    dontShowAgain() {
      localStorage.setItem("dontShowNewsletterPopup", "true");
      this.closePopup();
    },
    closePopup() {
      this.showPopup = false;
      this.isLeavingPage = false;
      // Record when the popup was closed
      this.popupLastShown = Date.now();
    },
    async submitNewsletter() {
      if (!this.acceptTerms) {
        this.setMessage("You must accept the terms and conditions.", "error");
        return;
      }
      try {
        const response = await axios.post(
          "https://connect.mailerlite.com/api/subscribers",
          {
            email: this.newsletterEmail,
            groups: ["131622667697522131"],
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization:
                "Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiOGEwYTgxMDY3YWE5YmRkZDg1NmJhNDhjMWU2ZTUyMDZlNmJjODJkYjlmNWQxNzZiMTkxZGM1NTAyYmM5MWNhY2U0M2RjMjhiODE1YzNjNDEiLCJpYXQiOjE3MjU1MjU0NDMuOTE0NzY4LCJuYmYiOjE3MjU1MjU0NDMuOTE0NzczLCJleHAiOjQ4ODExOTkwNDMuOTA3OTA3LCJzdWIiOiIxMDg2Mzk4Iiwic2NvcGVzIjpbXX0.MtFawkF7_PLhUa1M5LQLnUVA6FXx0Z-WnxmKpSy8HAfMVTq2X1Ne4osWFyikbFEReqKmm5uAzzey0k8auaTm-5hesiekQ3wYz0sS1hD_--qlmywRWICSp7rJdSnz3V1F9qqq0_iqB24awnIoaDAzaldEmfojcJSKL_99TosXL-hWxkMfoMLpqdyrDAsHFO9JxDnqy-10evC_a9Um2J0AURMfI_gryByLaLqUCADF9ITm7wRfYKitA0v6eJbMCHYmt53cMKErRkueV_SFq7o951oGeL9yk4MdkwO0FH1Xlkpxk3xaJmWA5dOAI5Pi3zinIFxto1l8CvAFfk2eF7D5ljs9L_p7B9ZbqjoL-EyRC5mjK-74ciMV0KzgQ1vbHO2dki6Krc0EEKvuJO14172dQCZ2Am_cT9AHgIeE4e7PvX0XekiF4G-vQAWX8017VXTp-LK2yex3IdBBZ-dRc8vl4Qqe3kwRovQJuquRGYtkRnBQLT1hqtoUbv3c_o0S66QJ8JFobUbf_apkDiPQrfQcWtZ1XeKSEoFXxw5OBCOO4VeS0nXfPS86-p0YuymIP0C1-5o2tByjxBR2cAycuwg58bZ-J8zomkeUTywwizjrVGv6k5uZpqiF7iWiYkZOot05ba6Nuch79TKeb5D66dxuXF86ihkcJKqbKyx9tCXMoQo",
            },
          }
        );

        this.setMessage(response.data.message || "You have successfully signed up for the newsletter!", "success");
        this.newsletterEmail = ""; // Clear the email input after success
        this.acceptTerms = false; // Reset the checkbox

        // Store that user has subscribed to prevent showing popup again
        localStorage.setItem("newsletterSubscribed", "true");

        // Close popup after success
        setTimeout(() => {
          this.closePopup();
        }, 2000);
      } catch (error) {
        if (error.response && error.response.data && error.response.data.message) {
          // If MailerLite API returns an error message (e.g. email already exists)
          this.setMessage(error.response.data.message, "error");
        } else {
          // Fallback to a generic error message
          this.setMessage("Failed to sign up for the newsletter. Please try again later.", "error");
        }
      }
    },
    setMessage(msg, type) {
      this.message = msg;
      this.messageType = type;

      // Clear the message after 5 seconds
      setTimeout(() => {
        this.message = "";
      }, 3000); // 3 seconds changed this from originally 5 seconds
    },
  },
  computed: {
    buttonLabel() {
      return this.acceptTerms ? "Subscribe" : "Accept T&Cs";
    },
  },
};
</script>

<style scoped>
.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.popup-container {
  background-color: rgb(0, 0, 0);
  padding: 3rem 2.25rem 2.5rem;
  /* border-radius: 8px; */
  border: 8px solid #fff;
  max-width: 600px;
  width: 90%;
  position: relative;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  animation: popup-appear 0.3s ease-out;
}
@media screen and (max-width: 479px) {
  .popup-container {
    padding: 2rem 1.25rem;
  }
  .popup-container .text-field {
    padding-top: 0.425em;
    padding-right: 0.625em;
    padding-bottom: 0.425em;
    padding-left: 0.625em;
    font-size: 1em;
  }
  .popup-container .submit-button {
    padding-top: 0.425em;
    padding-right: 0.625em;
    padding-bottom: 0.425em;
    padding-left: 0.625em;
    font-size: 0.85em;
  }
}

@keyframes popup-appear {
  0% {
    transform: scale(0.9);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.close-button {
  position: absolute;
  top: 12px;
  right: 12px;
  font-size: 32px;
  background: none;
  border: none;
  cursor: pointer;
  color: #fff;
}

.popup-footer {
  margin-top: 15px;
  text-align: center;
}

.dont-show-again {
  background: none;
  border: none;
  color: #666;
  text-decoration: underline;
  cursor: pointer;
  font-size: 0.8em;
}

.message-box {
  padding: 10px;
  margin-top: 10px;
  border-radius: 4px;
}

.success-box {
  background-color: #d4edda;
  color: #155724;
}

.error-box {
  background-color: #f8d7da;
  color: #721c24;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
